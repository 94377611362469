import { set } from 'lodash'

export type Field = Record<any, any>

export type TextractHightlightState = {
    fields: Field
}

export default {
    state: (): TextractHightlightState => ({
        fields: {},
    }),

    mutations: {
        setFields(state: TextractHightlightState, value: Field) {
            state.fields = value
        },

        setFieldHighlight(
            state: TextractHightlightState,
            { field, value }: Field
        ) {
            set(state.fields, field, value)
        },
    },

    getters: {
        isFieldHighlight(state: TextractHightlightState, field: string) {
            return !!state.fields[field]
        },
    },

    actions: {
        initFields(
            {
                commit,
            }: {
                commit: (...args: any[]) => void
            },
            fields: object
        ) {
            commit('setFields', fields)
        },

        setFields(
            {
                commit,
            }: {
                commit: (...args: any[]) => void
            },
            fields: object
        ) {
            commit('setFields', fields)
        },

        setFieldHighlight(
            {
                commit,
            }: {
                commit: (...args: any[]) => void
            },
            { field, value }: Field
        ) {
            commit('setFieldHighlight', { field, value })
        },
    },
}
